import React, { memo } from 'react'
import { Box, BoxProps, Typography, styled } from '@mui/material'

export interface ContentLeadTextProps extends BoxProps {
  text: string
}

const StyledBox = styled(Box)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.primary.main}`,
  paddingLeft: '20px',
}))

const ContentLeadText = ({ text, ...props }: ContentLeadTextProps) => {
  return (
    <StyledBox {...props}>
      <Typography variant="leadText" component="div">
        {text}
      </Typography>
    </StyledBox>
  )
}

export default memo(ContentLeadText)
